import { createRouter, createWebHistory } from "vue-router";
import assessmentRoutes from './assessment-routes.ts';
import { getCurrentUser } from '@Utils/authentication';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...assessmentRoutes,
    {
      path: "/",
      name: "Home",
      component: () => import("../views/NewHome.vue"),
    },
    {
      path: "/account/users",
      name: "AccountUsers",
      component: () => import("../views/AccountUsers.vue"),
    },
    {
      path: "/account/users/add",
      name: "Add Account User",
      component: () => import("../views/admin/AccountUsersAdd.vue"),
    },
    {
      path: '/test-api',
      name: 'test api',
      meta: {
        isOpen: true,
      },
      component: () => import('../views/test-api.vue'),
    },
    {
      path: '/user/:id',
      name: 'User Profile',
      props: true,
      component: () => import('../views/UserProfile.vue'),
    },
    {
      path: "/teams",
      name: "Teams List",
      component: () => import("../views/TeamsList.vue"),
    },
    {
      path: "/teams/create",
      name: "Teams Create",
      component: () => import("../views/TeamsCreate.vue"),
    },
    {
      path: "/teams/view/:id",
      name: "Teams View",
      props: true,
      component: () => import("../views/TeamsView.vue"),
    },
    {
      path: "/positions/view/:id",
      name: "Position View",
      props: true,
      component: () => import("../views/PositionView.vue"),
    },
    {
      path: "/teams/overlay/:id",
      name: "Team Overlay",
      props: true,
      component: () => import("../views/TeamOverlayView.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/LoginView.vue"),
      meta: {
        isLogin: true,
      },
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("../views/LogoutView.vue"),
      meta: {
        isLogin: true,
      },
    },
    {
      path: "/login/forgot-password",
      name: "ForgotPassword",
      component: () => import("../views/LoginView.vue"),
      meta: {
        isLogin: true,
      },
    },
    {
      path: "/password-reset",
      name: "PasswordReset",
      component: () => import("../views/LoginView.vue"),
      meta: {
        isLogin: true,
      },
    },
    {
      path: "/login/invitation",
      name: "NewPassword",
      component: () => import("../views/LoginView.vue"),
      meta: {
        isLogin: true,
      },
    },
    {
      path: "/generate-job-description",
      name: "generateJD",
      component: () => import("../views/JDView.vue"),
    },
    {
      path: "/job/:id",
      name: "Job Description View",
      props: true,
      component: () => import("../views/JobDescriptionView.vue"),
    },
    {
      path: "/job/edit/:id",
      name: "Job Description Edit",
      props: true,
      component: () => import("../views/JobDescriptionEdit.vue"),
    },
    {
      path: '/my-checklist/:id',
      name: 'User Checklist',
      props: true,
      meta: {
        isOpen: true,
      },
      component: () => import('../views/user-checklist.vue'),
    },
    {
      path: "/404",
      name: "NotFound",
      component: () => import("../views/NotFoundView.vue"),
    },
    { path: "/:pathMatch(.*)", name: "bad-not-found", redirect: "/404" },
  ],
});

router.beforeResolve(async (to, from, next) => {
  if (to.meta.test || to.meta.isOpen || to.meta.isLogin) {
    next();
  } else {
    let session = null;
    try {
      session = await getCurrentUser();
    } catch {
      session = null;
    }
    if (!session) {
      if (to.meta.isLogin) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  }
});

export default router;
