window.console.log = function() {
  if (import.meta.env.VITE_DEVELOPER_MODE) {
    return Function.prototype.bind.call(console.log, console );
  } else {
    return Function.prototype.bind.call(function(){}, function(){} );
  }
}();

import { createApp, provide, h, ref } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import http from "@Utils/axios";
//import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import router from "./router";
import "animate.css";
import "./assets/main.scss";

// Bulma
import "bulma-tooltip/dist/css/bulma-tooltip.min.css";

const jobCreationModal = ref(false);
const candidateCreationModal = ref({
  active: false,
  job: null,
  candidate: {
    firstName: null,
    lastName: null,
    email: null,
  },
});
const assignAssessmentModal = ref({
  active: false,
  assessments: [],
});
const pinia = createPinia();


const app = createApp({
  setup() {
    provide("http", http);
    provide("jobCreationModal", jobCreationModal);
    provide("candidateCreationModal", candidateCreationModal);
    provide("assignAssessmentModal", assignAssessmentModal);
  },

  render: () => h(App),
});

pinia.use(piniaPluginPersistedstate);
/*
if (import.meta.env.VITE_SENTRY_KEY) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_KEY,
    trackComponents: true,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", "https://dev.upwelling.io/"],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
  });
}
*/


app.use(pinia);
app.use(router);
app.mount("#app");
