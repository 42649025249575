<script setup lang="ts">
import { computed, inject, onMounted, ref, watch } from 'vue';
import { store } from '../stores/wayfinder.js';
import type { Ref } from 'vue';
import { useRouter } from 'vue-router';
import { Person } from '@IF/Person';

const props = defineProps<{
  filter?: string
}>();

const http = inject('http');
const searchDebounceTime = 1000;
let debounce: any = null;

const isFocused = ref(false);
const isDebouncing = ref(false);
const searchResults: Ref<Person[]> = ref([]);
const searchBar =ref(null);
const searchTerm = ref('');
const GlobalSearchContainer: Ref<HTMLElement | null>= ref(null);

const { isLoading, execute } =
  http('', { method: 'GET' }, false);

const isWaiting = computed(() => isDebouncing.value === true || isLoading.value === true);

const dropdownOpen = computed(() => {
  return searchTerm.value.length > 0 ||
  (isFocused.value === true && searchResults.value.length > 0);
});

async function searchDebounceEvent(event: any) {
  if (event.target.value.length > 0) {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      searchForUsers(event?.target?.value);
    }, searchDebounceTime);
  }
  if (event.target.value.length == 0) {
    searchResults.value = [];
  }
}

function focusSearch() {
  isFocused.value = true;
}
function blurOutSearch() {
  isFocused.value = false;
} 

function closeDropdowns(e) {
  isFocused.value = false;
  isDebouncing.value = false;
  searchTerm.value = '';
  searchResults.value = [];
}

async function searchForUsers(param: string) {
  if (param.length > 0) {
    const filter = props.filter ? `?filter=${props.filter}` : '';
    const { data } = await execute(`/operation/user-search/${param}${filter}`);
    searchTerm.value = param;
    searchResults.value = data.value.results;
  }
}

onMounted(() => {
  document.addEventListener('click', evt => {
    if (
      GlobalSearchContainer.value &&
      !GlobalSearchContainer.value.contains(evt?.target)
      && GlobalSearchContainer.value !== evt.target
    ) {
      closeDropdowns(evt);
    }
  });
});

watch(
  store,
  (newValue, oldValue) => {
    if(newValue.isActive) {
      searchBar.value.focus();
    }
  },
  {immediate: true}
);

watch(isWaiting, (newValue, oldValue) => {
  if(newValue) {
    store.disableIsActive();
  }
});

</script>
    
<template>
<div
  class="dropdown"
  :class="{'is-active': dropdownOpen}"
  ref="GlobalSearchContainer"
  >
  <div 
    class="dropdown-trigger">
    <div class="field">
      <p
        :class="{'is-loading': isLoading}"
        class="control is-expanded"
      >
      <input
        ref="searchBar"
        placeholder="e.g. Candidate Name"
        @keyup="searchDebounceEvent"
        @focus="focusSearch();"
        @blur="blurOutSearch"
        v-model="searchTerm"
      />
      </p>
    </div>
    <div
      class="dropdown-menu"
      role="menu"
    >
      <div
        v-show="!isWaiting"
        class="dropdown-content"
      >
      <div
        v-if="searchResults.length === 0"
        class="dropdown-item"
      >
        No results found
      </div>
        <ul>
          <li
            v-for="(item, idx) in searchResults"
            :key="idx"
          >
            <router-link
              class="dropdown-item search-item"
              @click="closeDropdowns"
              :to="{ name: item.entity_type === 'team' ? 'Team Overlay' : 'User Profile', params: { id: item.id } }"
            >
              {{ item?.name }}
              <div class="tag">{{item.entity_type }}</div>
            </router-link>
          </li>
        </ul>  
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
input {
    border: 4px white solid;
    border-left: 0;
    padding: 0.5rem;
    background: #e9e9e9;
    border-radius: 0 1.563rem 1.563rem 0;
    z-index: 10;
    &::placeholder {
      font-size: 1rem;
      font-family: 'Plus Jakarta Sans', sans-serif;
    }
}
.control.is-loading::after {
  position: absolute !important;
  right: 1rem;
  top: .9rem;
  z-index: 999;
  animation: spinAround 500ms infinite linear;
  border: 2px solid hsl(27.29deg 100% 50%);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1rem;
  position: relative;
  width: 1rem;
}
.field:not(:last-child) {
  margin-bottom: 0;
}
.dropdown {
  width: 100%;
}
.dropdown-menu {
    top: 20px;
    min-width: 20.25rem;
    position: absolute;
    z-index: 5;
    border: 4px white solid;
    left: -4px;
    padding: 1.5rem 0 .5rem;
    border-radius: 0 0 25px 25px;
    background: white;
    box-shadow: 0px 3px 9px -1px rgba(0,0,0,0.12);
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: white;
    border: 1px #e9e9e9 solid;
    margin: .5rem;
}
::-webkit-scrollbar-thumb {
    background: #e9e9e9;
}
::-webkit-scrollbar-thumb:hover {
    background: #797979;
}   
.dropdown-content {
    box-shadow: none;
    max-height: 250px;
    overflow-y: auto;
    padding: 1rem .5rem .5rem;
}
.dropdown-trigger {
  max-width: 20rem;
  width: 100%;
}
.dropdown-item {
    padding: 1rem;
    margin: .2rem 0;
    max-width: 18rem;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fafafa;
    &:hover {
        background: #e5f6ff;
    }
}
@media screen and (max-width: 800px) {
  .dropdown-item {
    max-width: 100%;
  }
  .dropdown-trigger {
    max-width: 100%;
  }
  .dropdown-menu {
    min-width: unset;
    width: 100%;
  }
}
</style>

