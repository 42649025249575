import { reactive } from 'vue';

export const store = reactive({
  instructions: [
    'Search for a candidate or add a new candidate to send an RQ',
    'Search for a candidate to give them an assessment.',
    'Search for a team to see their Team Overlay.',
    'Search for a candidate to build an interview for them.'
  ],
  isActive: false,
  message: '',
  toggleIsActive() {
    this.isActive = !this.isActive;
  },
  disableIsActive() {
    this.isActive = false;
  },
  enableIsActive() {
    this.isActive = true;
  },
  setMessage(mes: number) {
    this.isActive = true;
    this.message = this.instructions[mes];
  }
});