<script setup lang="ts">
import UpwellingLogo from '@/assets/upwellingLogo.vue';
import AutoComplete from '@Components/AutoComplete.vue';
import IconPlus from './icons/IconPlus.vue';
import {store} from "../stores/wayfinder";
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getUserAttributes, logout } from '@Utils/authentication';

const router = useRouter();
const route = useRoute();
const email = ref(null);
const adminToggle = ref(false);
const filter = ref(null);

async function signout() {
  await logout();
  router.push({ name: 'Login' });
}

const isAdmin = localStorage.getItem('isAdmin');
if(isAdmin === 'true') {
  adminToggle.value = true;
}

onMounted(async () => {
  try {
    const attr = await getUserAttributes();
    const name = attr.find(o => o.Name === 'email');

    email.value = name.Value;
  } catch {
    email.value = 'no user';
  }

});
</script>

<template>
  <header>
    <div class="layoutContainer">
      <div 
        class="logoContainer"
        :class="{isAdmin: adminToggle}">
        <router-link :to="{ name: 'Home' }">
          <UpwellingLogo />
          <h1 class="screenreaderText">Upwelling</h1>
        </router-link>
        <div v-if="!adminToggle" class="beta">Beta</div>
      </div>
      <div v-if="adminToggle && !route.meta.isOpen" class="mainSearch">
        <div class="search">
          <div class="selectContainer">
            <select v-model="filter">
              <option :value="null">All</option>
              <option value="candidates">Candidates</option>
              <option value="employees">Employees</option>
              <option value="teams">Teams</option>
            </select>
          </div>
          <AutoComplete :filter="filter" />
          <div v-show="store.isActive" class="messageContainer" >
            <div class="message">
              <p>{{ store.message }}</p>
            </div>
          </div>
        </div>
      </div>
      <button v-if="!route.meta.isOpen" class="signOut" @click="signout">
        Signout
      </button>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.selectContainer{
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-top: 9px solid white;
    border-right: 5px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 5px solid transparent;
    top: 41%;
    right: 12px;
    pointer-events: none;
  }
}
select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2.813rem;
  border: 4px white solid;
  padding: 0 1.5rem;
  border-radius: 25px 0 0 25px;
  text-align: center;
  background: $primary;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.messageContainer {
  top: 4rem;
  z-index: 3;
  position: absolute;
  left: 9rem;
  .message {
     width: 300px;
     background: #ffe3cb;
     font-size: 1.1rem;
     font-weight: 400;
     padding: 1rem;
     border: 4px solid $secondary;
     border-radius: 10px;
     &:before {
       content: "";
       display: block;
       position: absolute;
       top: -24px;
       left: 5px;
       border-top: 10px solid transparent;
       border-bottom: 15px solid #ff7400;
       border-left: 10px solid transparent;
       border-right: 10px solid transparent;
     }
     p {
       margin: 0;
     }
   }
}
:deep {
  p {
    margin: 0;
  }
}
:focus {
  outline: 2px dashed $secondary;
  outline-offset: 2px;
}
.beta {
  color: black;
  margin-left: 1rem;
  font-size: .8rem;
  background: #c9ecff;
  font-weight: 700;
  padding: 0.3rem 1rem;
  border-radius: 25px;
}
.mainSearch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-basis: 30rem;
  justify-content: center;
}
.search {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin: .5rem;
  margin-left: 0;
  max-width: 29.063rem;
  width: 100%;
}
.layoutContainer {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  padding: .5rem 1rem;
  position: relative;
}
header {
  background: $primary;
  background: $primary;
  display: flex;
  justify-content: center;
}
.logoContainer {
  display: flex;
  align-items: center;
  flex-basis: 250px;
  margin-right: 1rem;
  &.isAdmin {
    flex-basis: 150px;
  }
  svg {
    width: 100%;
    height: auto;
  }
}
button.signOut {
  color: white;
  background: none;
  border: none;
  font-size: 1rem;
  text-decoration: underline;
  padding: 0.5rem;
  min-width: auto;
  cursor: pointer;
  margin-right: .75rem;
}
@media screen and (max-width: 800px) {
  .mainSearch {
    flex-basis: 100%;
  }
  .search {
    max-width: 100%;
  }
  .signOut {
    position: absolute;
    top: 1.5rem;
    right: 0;
  }
}
@media screen and (max-width: 350px) {
  .logoContainer {
    flex-basis: 115px;
  }
}
</style>
