<script setup lang="ts">
import { inject } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import Header from '@Components/Header.vue';

const route = useRoute();

</script>

<template>
  <Header v-if="!route.meta.isLogin && !route.meta.isOpen" />
  <main :class="{invertedBG: route.meta.isLogin}">
    <div class="max-width">
      <RouterView />
    </div>
  </main> 
</template>

<style scoped lang="scss">
main {
  display: flex;
  justify-content: center;
  margin-top: .6rem;
  padding-bottom: 2rem;
}
.invertedBG {
  background: $primary;
  margin: 0;
}
.max-width {
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1250px) {
  main {
    margin: .6rem 1rem;
  }
}
</style>
