import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

const UserPoolIdVar = import.meta.env.VITE_POOL_ID;
const ClientIdVar = import.meta.env.VITE_CLIENT_ID;

interface Session {
  valid: boolean,
  data: any,
}

/**
 * Creates a new Cognito User Pool to authenticate the user from
 *
 * @param      {string}           UserPoolId  The user pool identifier
 * @param      {string}           ClientId    The client identifier
 * @return     {CognitoUserPool}  The cognito user pool.
 */
const createUserPool = (UserPoolId : string, ClientId : string) => {
  return new CognitoUserPool({
    UserPoolId,
    ClientId,
  });
};

export const createAuthenticationDetails = (username : string, password : string) => {
  const authImplementation = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  return authImplementation;
};

/**
 * Creates an instance of a cognito user for authentication
 *
 * @param      {string}  username  The username
 * @param      {string}  password  The password
 */
export const createCognitoUser = (username: string) => {
  const userPool = createUserPool(UserPoolIdVar, ClientIdVar);
  return new CognitoUser({
    Username: username,
    Pool: userPool,
  });
};

/**
 * Handler for confirming the mfa of a user trying to log in
 *
 * @param      {string}   code    The code
 * @return     {Promise}  callback of possible statuses
 */
export const sendMFACode = (code: string, user: CognitoUser) => {
  return new Promise((resolve, reject) => {
    user.sendMFACode(code, {
      onSuccess: session => {
        resolve(session);
      },
      onFailure: err => {
        reject(err);
      },
      mfaRequired: () => {
        reject('mfaRequired');
      },
    });
  });
};

/**
 * Called the first time a user logs in, to complete their new password challenge
 *
 * @param      {string}       newPassword  The new password
 * @param      {CognitoUser}  user         The user
 */
export const firstTimeLogin = async (newPassword: string, email: string, oldPassword: string) => {
  const user = createCognitoUser(email);
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: oldPassword,
  });

  if (user) {
    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: session => resolve(session),
        onFailure: err => reject(err),
        mfaRequired: () => reject('mfaRequired'),
        newPasswordRequired: () => {
          user.completeNewPasswordChallenge(newPassword, {}, {
            onSuccess: session => resolve(session),
            onFailure: err => reject(err),
            mfaRequired: () => reject('mfaRequired'),
          });
        },
      });
    });
  } else {
    return null;
  }
};

/**
 * Logs the user in
 * Set to only handle success and failure conditions, not newPasswordRequired event
 */
export const login = async (email: string, password: string) => {
  const user = createCognitoUser(email);
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: session => {
        resolve(session);
      },
      onFailure: err => {
        reject(err);
      },
      mfaRequired: () => {
        reject('mfaRequired');
      },
      newPasswordRequired: () => {
        reject('newPasswordRequired');
      },
    });
  });
};

/**
 * Signs a user up
 * **/
export const signUp = async (username: string, email: string, password: string, phone?: string) => {
  const userPool = createUserPool(UserPoolIdVar, ClientIdVar);

  return new Promise((resolve, reject) => {
    const attributeList: CognitoUserAttribute[] = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email
      })
    ];


    userPool.signUp(
        username,
        password,
        attributeList,
        null,
        (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

/**
 * Log out user from application
 *
 */
export const logout = () => {
  const userPool = createUserPool(UserPoolIdVar, ClientIdVar);
  const user = userPool?.getCurrentUser();

  if (user) {
    return new Promise(resolve => {
      resolve(user.signOut());
    });
  }
};

/**
 * Get currently logged in user from session
 *
 * @return     {Promise}
 */
export async function getCurrentUser(): Promise<Session> {
  const userPool = createUserPool(UserPoolIdVar, ClientIdVar);
  const cognitoUser = userPool?.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession(function (err: any, session: any){
        if (err) {
          reject({ valid: false, data: err.message || JSON.stringify(err) });
        }

        resolve({ valid: session.isValid(), data: session });
      });
    } else {
      reject({ valid: false, data: null });
    }
  });
}

export const getUserAttributes = () => {
  const userPool = createUserPool(UserPoolIdVar, ClientIdVar);
  const cognitoUser = userPool?.getCurrentUser();
  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err: unknown) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.getUserAttributes((attrErr, attributes) => {
            if (attrErr) {
              reject(attrErr);
            } else {
              resolve(attributes);
            }
          });
        }
      });
    } else {
      reject(Error('No user'));
    }
  });
};

export const sendForgotPassword = (email: string) => {
  const userPool = createUserPool(UserPoolIdVar, ClientIdVar);
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: data => {
        resolve(data);
      },
      onFailure: err => {
        console.error(err.message || JSON.stringify(err));
        reject(err.message);
      },
    });
  });
};

export const updatePassword =
  async(email: string, password: string, code: string) => {
    const userPool = createUserPool(UserPoolIdVar, ClientIdVar);
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      user.confirmPassword(code, password, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  };
