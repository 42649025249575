/*
import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

export default apiClient;
*/
import axios from 'axios';
import { useAxios } from '@vueuse/integrations/useAxios';
import { getCurrentUser } from '@Utils/authentication';

const client = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});


client.interceptors.request.use(async request => {
  let cognitoUser = null;
  try {
    cognitoUser = await getCurrentUser();
  } catch {
    //  no nothing
  }

  if (cognitoUser) {
    request.headers.Authorization = `${cognitoUser?.data?.accessToken?.jwtToken}`;
  }
  return request;
});

client.interceptors.response.use(config => {
  return config;
},
async error => {
  if (!error.response) {
    console.error('Missing Authentication Token');
    console.log(error);
  }

  if (error.response?.status === 406 || error.response?.status === 500) {
    location.replace(`${window.location.origin}/404`);
    return null;
  }

  if (error.response.status === 401 && error.config && !error.config.isRetryRequest) {
    const config = { ...error.config, isRetryRequest: true };
    return client(config);
  }
  const errorObject = {
    statusCode: error.response.status,
    messageCode: error.code,
    name: error.name,
    message: error.message,
    response: error.response,
  };
  throw errorObject; //error;
});

const clientCall = (url: string, config: any, immediately = true) =>
  useAxios(url, config, client, {
    immediate: immediately
  });

export default clientCall;

/*
http.interceptors.response.use(undefined, async error => {
  if (error && axios.isCancel(error)) {
    return Promise.reject(new Error('Cancelled'));
  }

  if (error.message === 'Network Error') {
    throw error;
  }

  if (error.response.status === 401 && error.config && !error.config.isRetryRequest) {
    await store.dispatch('auth/localAuth');
    const config = {
      ...error.config,
      isRetryRequest: true,
    };

    return http(config);
  }

  if (error.response.status === 401) {
    await store.dispatch('auth/logout');
    await router.push({ name: 'login' });

    toast(error.response.data.error, 'is-error');
  } else {
    console.error('Error Reported: API -', error);

    throw error;
  }
});
*/
