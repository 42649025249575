const assessmentRoutes = [{
  path: '/profile/:user/assessment/:assessment',
  name: 'Assessment',
  component: () => import("../views/assessment/Layout.vue"),
  props: true,
  meta: {
    isOpen: true
  },
  children: [
    {
      path: 'active',
      name: 'Assessment Active',
      component: () => import('../views/assessment/ActiveAssessment.vue'),
    },
    {
      path: 'finished',
      name: 'Assessment Finished',
      component: () => import('../views/assessment/Finished.vue'),
    },
  ],
}];

export default assessmentRoutes;
